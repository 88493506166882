import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomsModal from '../components/CustomModal';
import { UserContext } from '../context/User.context';
import PngIcons from '../assets/images/pngIcons'
import { BusinessService } from "../services";
import { CircularProgress } from "@mui/material";
import SvgIcons from '../assets/images/svgIcons';


export default function Spend() {

    const location = useLocation();
    const history = useNavigate();
    const user = useContext(UserContext)
    const [success, setSuccess] = useState(false);
    const [passz, setPassz] = useState(null);
    const [spending, setSpending] = useState('$0')
    const [redeeming, setRedeeming] = useState('0')
    const [payloadz, setPayloadz] = useState(null)
    const [dealzz, setDealzz] = useState(null)
    const [publicPass, setPublicPass] = useState(null)
    const [amountprice, setAmountprice] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [selectedDeal, setSelectedDeal] = useState(null)
    const [brand, setBrand] = useState();
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [openKeypad, setOpenKeypad] = useState(false);



    //NEW PAGE
    const [pass, setPass] = useState();
    const [deals, setDeals] = useState(null);
    const [pointRedeem, setPointRedeem] = useState(false);
    const [dataloader, setDataLoader] = useState(true);

    const onLoad1 = async () => {
        let pass = location?.state?.pass
        setPassz(pass);
        addData(location?.state);
        const brand = await BusinessService.GetPublicBrand({ query: { _id: location.state.payload.brandId } });
        if (brand && brand.response && brand.response.success) {
            if(brand.response.data[0]?.integrations?.cloudwaitress?.length>0) setOpenKeypad(true)
            setBrand(brand.response.data[0]);
        if(brand.response.data[0].subtype==='cashback') setRedeeming('-')
        }
    }
    useEffect(() => {
        onLoad1();
    }, [])
    const addData = async (dd) => {
        let availableFlows = await localforage.getItem('flows');
        let pay = location.state.payload;
        const growthtool = await BusinessService.GetQueriedGrowthtool({ query: { _id: dd.pass.growthToolId } })
        if (growthtool && growthtool?.response && growthtool?.response?.success) {
            pay.flowIds = growthtool.response.data.flowId

            if (pay.passId) {
                const { response, error } = await BusinessService.GetPublicPass({ query: { _id: pay.passId, delete: false } });
                setPublicPass(response.data[0])
                // //console.log('response 22', response);
                let payload = { brandId: pay.brandId, passId: pay.passId, flowIds: pay.flowIds }
                //console.log('payload', payload);
                // const flowData = await BusinessService.GetFlows({ payload });
                // console.log("Flooo", flowData?.response);
                // if (flowData?.response?.success) {
                //     console.log('flowData?.response', flowData?.response);
                //     setDealzz(flowData.response.data)
                //     let tosendAgainflows = [];
                //     if (availableFlows?.length) {
                //         for (let index = 0; index < availableFlows.length; index++) {
                //             if ((availableFlows[index].dateStamp + 2629800000) < Date.now()) {
                //                 // //console.log('TimeOver');
                //             }
                //             else {
                //                 if (location?.state?.pass._id !== availableFlows[index].user._id) {
                //                     tosendAgainflows.push(availableFlows[index])
                //                 }
                //             }

                //         }
                //     }





                //     await localforage.setItem("flows", [
                //         ...tosendAgainflows,
                //         {
                //             user: location?.state?.pass,
                //             flow: location?.state?.payload,
                //             flows: flowData.response.data,
                //             dateStamp: new Date().valueOf()
                //         }]);
                // }
                // else {
                //     //console.log('checkError', flowData.response);

                // }
            }




            setPayloadz(pay)
            setRefreshData(false)
            // //console.log('payloadz', payloadz);
        }
    }
    const ConfirmSpendings = async () => {
        // setCheckoutLoader(true)
        let user = await localforage.getItem('user');
        let updateFlows = await localforage.getItem('flows')

        let payload = {
            amount: spending.replace('$', ''),
            passId: passz._id,
            brandId: passz.brandId,
            type: 'amount',
            platform: 'bc web',
            // amount: spending.replace('$', ''),
            // discountType: 1,
            // discountValue: 0,
            // flowId: location.state.payload.flowIds,
            // idKey: user.idKey,
            // passCode: passz.passCode,
            // transactionType: 'amount',
        }

        const { error, response } = await BusinessService.BusinessCheckout({ payload })

        if (response && response.success) {
            console.log("Pointss", response)
            console.log("Current spending points", updateFlows)

            if (response?.data?.loyaltyCard?.points) {

                for (let idx = 0; idx < updateFlows?.length; idx++) {
                    updateFlows[idx].user.loyaltyCard.currentPoints += response.data.loyaltyCard.points;
                }
            }
            setSuccess(true);

            // document.getElementsByClassName('fs-88Imp ccSace')[0].value = ""
            await localforage.setItem("flows", updateFlows);
            setSpending('$0');
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)

        }

        // setCheckoutLoader(false);
        // history('/scanner')

    }
    const handleDealSelect = (deal) => {
        console.log('zoop deal', deal);
        console.log('selectedDeal', selectedDeal);
        if (selectedDeal && selectedDeal.id == deal.id) {
            setSelectedDeal(null)
        } else {
            setSelectedDeal(deal)
        }
    }
    const refresh = () => {
        setRefreshData(true)
        onLoad()
    }
    //ANOTHER P{AGE}
    const onLoad = async () => {
        if (!location.state.pass) {
            console.log('1')
            const { response, error } = await BusinessService.GetPublicPass({ query: { _id: location.state.payload.passId, delete: false } });
            //console.log('response payLoad', response);
            if (response && response.success) {
                console.log('2')
                setPass(response.data[0])
            }
        } else {
            console.log('33')
            setPass(location?.state?.pass)
        }

        if (location.state.deal) {
            console.log('3')
            setDeals(location.state.deal)
            setPass(location.state.pass)

        } else {
            console.log('4')
            setDataLoader(true);
            if (location && location.state && location.state.payload) {
                console.log('5')
                let payload = {
                    brandId: location.state.payload.brandId,
                    passId: location.state.payload.passId,
                    flowIds: location.state.payload.flowIds
                }
                console.log('payload', payload);
                const flowData = await BusinessService.GetFlows({ payload });
                console.log('flowData',flowData);

                if (flowData.response && flowData.response.success) {
                    console.log('6')
                    // for (const iterator of flowData.response.data) {
                    //     if(iterator?.ExpiryData && iterator?.dealExpiration){
                    //         if(iterator?.ExpiryData && iterator?.dealExpiration)
                    //         ((+iterator.ExpiryData) + (+iterator?.dealExpiration)) > new Date().valueOf()
                    //     }
                    // }
                    let data = [...flowData.response.data]
                    for (let index = 0; index < data.length; index++) {
                        if (data[index]?.ExpiryData && data[index]?.dealExpiration) {
                            if (new Date(data[index].ExpiryData).valueOf() + Number(data[index]?.dealExpiration) > new Date().valueOf()) {
                                data[index].valid = false
                            }

                        }

                    }
                    data.sort((a, b) => {
                        if (a.valid === b.valid) {
                            return 0;
                        }

                        if (a.valid === true) {
                            return -1;
                        }

                        return 1;
                    });
                    console.log('data',data);
                    setDeals(data);

                    setDataLoader(false);
                }
                setDataLoader(false);
                setRefreshData(false)
            }
        }


    }
    useEffect(() => {
        console.log('location', location);
        if (location.state == null) {
            history('/scanner', { replace: true })
        }
        else {
            onLoad();
        }
    }, []);
    const pointsRedemmedSubmitR = async () => {
        console.log('redeeming123',redeeming);
        console.log('redeeming123',typeof redeeming);
        if(redeeming[0]=== '-') console.log('--------');
        
        
        // setCheckoutLoader(true)
        let payload = {
            passId: passz._id,
            brandId: passz.brandId,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            // removePoints: redeeming,
            removePoints: Math.abs(Number(redeeming)).toString(),
            type : 'redeem points',
            platform : 'bc web'
        }

        const { response, error } = await BusinessService.BusinessCheckout({ payload });
        // const { response, error } = await BusinessService.SingleLoyalitySelf({ payload });

        if (response && response.success) {
            setSuccess(true);
            // setCheckoutLoader(false)
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }

        setPointRedeem(false);

    }
    const pointsRedemmedSubmitA = async () => {
        console.log('redeeming123',redeeming);
        console.log('redeeming123',typeof redeeming);
        // if(redeeming[0]=== '-') console.log('--------');
        
        
        // setCheckoutLoader(true)
        let payload = {
            passId: passz._id,
            brandId: passz.brandId,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            // removePoints: redeeming,
            addPoints: Math.abs(Number(redeeming)).toString(),
            type : 'add points',
            platform : 'bc web'
        }

        const { response, error } = await BusinessService.BusinessCheckout({ payload });
        // const { response, error } = await BusinessService.SingleLoyalitySelf({ payload });

        if (response && response.success) {
            setSuccess(true);
            // setCheckoutLoader(false)
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }

        setPointRedeem(false);

    }
    const handleTransaction = async (amount) => {
        // setLoader(true)
        // setCheckoutLoader(true)
        const user = await localforage.getItem('user');

        let payload = {
            // amount: amount,
            // availDealOffer: true,
            // passCode: passz.passCode,
            // dealOffer: {
            //     name: selectedDeal?.deal,
            //     isFromBusinessConnector: '1',
            //     _id: selectedDeal?.id,
            //     image: selectedDeal?.image,
            //     description: selectedDeal?.description,
            //     condition: selectedDeal?.condition
            // },
            // discountType: 1,
            // discountValue: 0,
            // flowId: location.state.payload.flowIds,
            // idKey: user.idKey,
            // location: {
            //     lat: brand?.location.lat,
            //     address: brand?.location.address,
            //     lng: brand?.location.lng
            // },
            // pointUsed: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            // transactionType: 'giveaway',
            amount: amount,
            passId: passz._id,
            brandId: passz.brandId,
            data:{
                dealOffer: {
                    name: selectedDeal?.deal,
                    isFromBusinessConnector: '1',
                    _id: selectedDeal?.id,
                    image: selectedDeal?.image,
                    description: selectedDeal?.description,
                    condition: selectedDeal?.condition
                },
            },
            dealId: selectedDeal?.id,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            removePoints: brand?.integrations?.cloudwaitress?.length>0?((selectedDeal?.condition === 'point') ? selectedDeal?.conditionValue : 0):((selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0),
            type : 'deal',
            platform : 'bc web',
        }

        console.log('transition payload',payload)
        const { error, response } = await BusinessService.BusinessCheckout({ payload });


        if (response && response.success) {
            console.log('responseresponse', response);
            nextWorking(response)
            setSuccess(true);
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }
        // setCheckoutLoader(false)
    }
    const handleTransactionZoop = async (amount) => {
        // setLoader(true)
        // setCheckoutLoader(true)
        const user = await localforage.getItem('user');
        let payload = {
            amount: 0,
            passId: passz._id,
            brandId: passz.brandId,
            dealId: selectedDeal?.id,
            data:{
                dealOffer: {
                    name: selectedDeal?.deal,
                    isFromBusinessConnector: '1',
                    _id: selectedDeal?.id,
                    image: selectedDeal?.image,
                    description: selectedDeal?.description,
                    condition: selectedDeal?.condition
                },
            },
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            // removePoints: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            removePoints: brand?.integrations?.cloudwaitress?.length>0?((selectedDeal?.condition === 'point') ? selectedDeal?.conditionValue : 0):((selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0),
            type : 'deal',
            platform : 'bc web',
            // amount: '0',
            // availDealOffer: true,
            // passCode: passz.passCode,
            // dealOffer: {
            //     name: selectedDeal?.deal,
            //     isFromBusinessConnector: '1',
            //     _id: selectedDeal?.id,
            //     image: selectedDeal?.image,
            //     description: selectedDeal?.description,
            //     condition: selectedDeal?.condition
            // },
            // discountType: 1,
            // discountValue: 0,
            // flowId: location.state.payload.flowIds,
            // idKey: user.idKey,
            // location: {
            //     lat: brand?.location.lat,
            //     address: brand?.location.address,
            //     lng: brand?.location.lng
            // },
            // pointUsed: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            // transactionType: 'giveaway',
        }
        const { error, response } = await BusinessService.BusinessCheckout({ payload });


        if (response && response.success) {
            console.log('responseresponse', response);
            nextWorking(response)

        }
        let Zpayload = {
            // location: {
            //     address: 'Karachi, Pakistan',
            //     lat: 24.91123372672892,
            //     lng: 67.08654837127668
            // },
            // brandId: passz.brandId,
            // passId: passz._id,
            // subtract: true,
            // points: redeeming


            passId: passz._id,
            brandId: passz.brandId,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            addPoints: Math.abs(Number(redeeming)).toString(),
            type : 'add points',
            platform : 'bc web'
        }

        // const SingleLoyalitySelf = await BusinessService.SingleLoyalitySelf({ payload: Zpayload });
        const SingleLoyalitySelf = await BusinessService.BusinessCheckout({ payload: Zpayload });
        if (SingleLoyalitySelf.error) {
            console.log('eerror', Zpayload, SingleLoyalitySelf.error)
        }
        if (SingleLoyalitySelf.response && SingleLoyalitySelf.response.success) {
            setSuccess(true);
            // setCheckoutLoader(false)
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }

        // setCheckoutLoader(false)
    }
    const handleTransactionZoopR = async (amount) => {
        // setLoader(true)
        // setCheckoutLoader(true)
        const user = await localforage.getItem('user');
        let payload = {
            amount: 0,
            passId: passz._id,
            brandId: passz.brandId,
            dealId: selectedDeal?.id,
            data:{
                dealOffer: {
                    name: selectedDeal?.deal,
                    isFromBusinessConnector: '1',
                    _id: selectedDeal?.id,
                    image: selectedDeal?.image,
                    description: selectedDeal?.description,
                    condition: selectedDeal?.condition
                },
            },
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            // removePoints: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            removePoints: brand?.integrations?.cloudwaitress?.length>0?((selectedDeal?.condition === 'point') ? selectedDeal?.conditionValue : 0):((selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0),
            type : 'deal',
            platform : 'bc web',
            // amount: '0',
            // availDealOffer: true,
            // passCode: passz.passCode,
            // dealOffer: {
            //     name: selectedDeal?.deal,
            //     isFromBusinessConnector: '1',
            //     _id: selectedDeal?.id,
            //     image: selectedDeal?.image,
            //     description: selectedDeal?.description,
            //     condition: selectedDeal?.condition
            // },
            // discountType: 1,
            // discountValue: 0,
            // flowId: location.state.payload.flowIds,
            // idKey: user.idKey,
            // location: {
            //     lat: brand?.location.lat,
            //     address: brand?.location.address,
            //     lng: brand?.location.lng
            // },
            // pointUsed: (selectedDeal?.condition[0].condition === 'point') ? selectedDeal?.condition[0].conditionValue[1] : 0,
            // transactionType: 'giveaway',
        }
        const { error, response } = await BusinessService.BusinessCheckout({ payload });


        if (response && response.success) {
            console.log('responseresponse', response);
            nextWorking(response)

        }
        let Zpayload = {
            // location: {
            //     address: 'Karachi, Pakistan',
            //     lat: 24.91123372672892,
            //     lng: 67.08654837127668
            // },
            // brandId: passz.brandId,
            // passId: passz._id,
            // subtract: true,
            // points: redeeming


            passId: passz._id,
            brandId: passz.brandId,
            location: {
                lat: brand?.location.lat,
                address: brand?.location.address,
                lng: brand?.location.lng
            },
            removePoints: Math.abs(Number(redeeming)).toString(),
            type : 'redeem points',
            platform : 'bc web'
        }

        // const SingleLoyalitySelf = await BusinessService.SingleLoyalitySelf({ payload: Zpayload });
        const SingleLoyalitySelf = await BusinessService.BusinessCheckout({ payload: Zpayload });
        if (SingleLoyalitySelf.error) {
            console.log('eerror', Zpayload, SingleLoyalitySelf.error)
        }
        if (SingleLoyalitySelf.response && SingleLoyalitySelf.response.success) {
            setSuccess(true);
            // setCheckoutLoader(false)
            setTimeout(() => {
                setSuccess(!success); history('/scanner')
            }, 2000)
        }

        // setCheckoutLoader(false)
    }
    const nextWorking = async (response) => {
        if (response?.success) {
            const availableFlows = await localforage.getItem('flows');
            let payload = { brandId: response.data.brandId, passId: response.data.passId, flowIds: location.state.payload.flowIds }
            //console.log('payload', payload);
            // const flowData = await BusinessService.GetFlows({ payload });
            // if (flowData.response.success) {
            //     for (let index = 0; index < availableFlows.length; index++) {
            //         if ((availableFlows[index].flow.brandId == response.data.brandId) && (availableFlows[index].flow.passId == response.data.passId)) {
            //             availableFlows[index].flows = flowData?.response?.data
            //         }
            //         await localforage.setItem("flows", availableFlows);
            //     }
            // }
        }
    }
    const CheckOutMain = () => {
        setCheckoutLoader(true)
        if (!amountprice && +spending.replace('$', '') > 0 && !selectedDeal) {
            ConfirmSpendings()
        }
        if (amountprice && +redeeming > 0 && !selectedDeal) {
            pointsRedemmedSubmitA()
        }
        if (amountprice && +redeeming < 0 && !selectedDeal) {
            pointsRedemmedSubmitR()
        }
        if (selectedDeal && (+spending.replace('$', '') == 0 || +spending.replace('$', '') == '') && (+redeeming == 0 || redeeming == '')) {
            handleTransaction('0')
        }
        if (selectedDeal && !amountprice && +spending.replace('$', '') > 0) {
            handleTransaction(spending.replace('$', ''))
        }
        if (selectedDeal && amountprice && +redeeming > 0) {
            handleTransactionZoop()
        }
        if (selectedDeal && amountprice && +redeeming < 0) {
            handleTransactionZoopR()
        }
        setTimeout(() => {
            setSuccess(true);
            setSpending('$0');
            setCheckoutLoader(false)
            // setTimeout(() => {
            //     // setSuccess(!success); history('/scanner')
            // }, 2000);
        }, 2000);
    }
    const settingTheSpending = (e) => {
        console.log('target', e.target.value);
        if (e.target.value.length >= 1) setSpending(e.target.value)
    }
console.log('deals',deals)
console.log('selecetdDeal',selectedDeal)

    let KeyBoardKeys = [
        {
            "key": 1,
            "data": 1,
            'width': false
        },
        {
            "key": 2,
            "data": 2,
            'width': false
        },
        {
            "key": 3,
            "data": 3,
            'width': false
        },
        {
            "key": 4,
            "data": 4,
            'width': false
        },
        {
            "key": 5,
            "data": 5,
            'width': false
        },
        {
            "key": 6,
            "data": 6,
            'width': false
        },
        {
            "key": 7,
            "data": 7,
            'width': false
        },
        {
            "key": 8,
            "data": 8,
            'width': false
        },
        {
            "key": 9,
            "data": 9
        },
        // {
        //     "key": <SvgIcons.hideKeyboard />,
        //     "data": "close",
        //     'width': true
        // },
        {
            "key": '-',
            "data": '-',
            'width': true
        },
        {
            "key": 0,
            "data": 0,
            'width': true
        },

        {
            "key": <SvgIcons.deleteOne />,
            "data": "delete",
            'width': true
        }
    ]


    const setKey = (key) => {
        console.log('key',key);
        
        if(key === 'delete' && amountprice === true && brand.subtype === 'cashback' && redeeming.length===1 && redeeming[0] === '-'){}
        else if(key !== 'delete' && key !== 'close' && key !== '-' && amountprice === true && brand.subtype === 'cashback' && redeeming[0] === '-'){
            console.log('Math.abs(redeeming)',redeeming);
            console.log('Math.abs(redeeming)',parseInt(Math.abs(redeeming.toString())));
            console.log('Math.abs(redeeming)',passz[passz.passType].currentPoints);
            
            if( redeeming === '-' || parseInt(Math.abs(redeeming.toString())) < passz[passz.passType].currentPoints){
                if (redeeming !== '0' && amountprice) {
                    let newSpending = `${redeeming}` + key
                    if(parseInt(Math.abs(newSpending.toString())) <= passz[passz.passType].currentPoints){
                        setRedeeming(newSpending)
                    }
                }
            }
        }
        else{
            if(key === '-' && amountprice === true && (redeeming === '0' || redeeming === '')){
                if (redeeming !== '0' && amountprice) {
                    let newSpending = `${redeeming}` + key
                    setRedeeming(newSpending)
                }
                else if (redeeming == '0' && amountprice) {
                    let newSpending = key
                    setRedeeming(newSpending)
                }
            }
            else{
                console.log('R111', key, spending, amountprice);
                if (key !== 'close' && key !== 'delete' && key !== '-') {
                    if (spending == '$0' && !amountprice) {
                        let newSpending = '$' + key
                        setSpending(newSpending)
                    }
                    else if (!amountprice) {
                        let newSpending = spending + key
                        setSpending(newSpending)
                    }
                    else if (redeeming !== '0' && amountprice) {
                        let newSpending = `${redeeming}` + key
                        setRedeeming(newSpending)
                    }
                    else if (redeeming == '0' && amountprice) {
                        let newSpending = key
                        setRedeeming(newSpending)
                    }
                }
                if (key == 'delete' && spending !== '$' && !amountprice) {
                    let newString = spending.slice(0, -1);
                    setSpending(newString)
                }
                if (key == 'delete' && spending !== '$' && amountprice) {
                    console.log('R11');
                    
                    let newString = redeeming.slice(0, -1);
                    setRedeeming(newString)
                }
                if (key == 'delete' && spending === '$' && amountprice) {
                    console.log('R11');
                    
                    let newString = redeeming.slice(0, -1);
                    setRedeeming(newString)
                }
                if (key == 'close') setOpenKeypad(false)
            }
        }

    }
    useEffect(() => {

        console.log('spending',spending);
        var spendingCount = (spending.match(/\./g) || []).length;
        if (spendingCount > 1) {
            let arrayA = spending.split('');
            let lastDotIndex = arrayA.lastIndexOf('.');
            arrayA.splice(lastDotIndex, 1);
            let result = arrayA.join('');
            setSpending(result)
        }
        console.log('redeming',redeeming);
        var redeemingCount = (redeeming.toString().match(/\./g) || []).length;
      

        if (redeemingCount > 1) {
            let arrayB = redeeming.split('');
            let lastDotIndexB = arrayB.lastIndexOf('.');
            arrayB.splice(lastDotIndexB, 1);
            let resultB = arrayB.join('');
            setRedeeming(resultB)
        }

    }, [redeeming, spending])


    useEffect(() => {
        if (passz && !selectedDeal && redeeming > passz[passz.passType].currentPoints) {
            setRedeeming(`${passz[passz.passType].currentPoints }`  )
        }
        if (passz && selectedDeal && (+redeeming  + selectedDeal.subtract) > passz[passz.passType].currentPoints) {
            setRedeeming(`${passz[passz.passType].currentPoints -  selectedDeal.subtract}`  )
        }
    }, [redeeming,selectedDeal])


    const [active, setActive] = useState('Amount'); // Default active state
    const [show, setShow] = useState(false); 

    const toggleSwitch = (option) => {
        setActive(option);
    };



    return (
        <>


<div id='spend'>
    {console.log('redeeming',redeeming)}
    {console.log('R1amountprice',amountprice)}
    {console.log('R1spending',spending)}
    <div className='hide2 pt_34' style={{ marginLeft:'20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <path d="M20 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20C36.6666 10.7953 29.2047 3.33337 20 3.33337C10.7952 3.33337 3.33331 10.7953 3.33331 20C3.33331 29.2048 10.7952 36.6667 20 36.6667Z" stroke="#787878" stroke-width="1.5"/>
  <path d="M12.5 28.3333C16.3862 24.263 23.572 24.0713 27.5 28.3333M24.1585 15.8333C24.1585 18.1345 22.2903 20 19.9858 20C17.6815 20 15.8133 18.1345 15.8133 15.8333C15.8133 13.5321 17.6815 11.6666 19.9858 11.6666C22.2903 11.6666 24.1585 13.5321 24.1585 15.8333Z" stroke="#787878" stroke-width="1.5" stroke-linecap="round"/>
</svg>
                        <div className='customer-info'>
                            
                            <div className='customer-info-name'>{passz && passz.userData.firstName} {passz && passz.userData.lastName} </div>
                            <div className='customer-info-email'>{passz && passz.userData.email}</div>
                        </div>
                    </div>

                </div>
                <span className='home_back' onClick={() => history('/scanner')}>
                    
                    <img
                        className='login-black'
                        src={PngIcons.Bell}
                        alt=''
                        style={{cursor : 'pointer'}}
                        // onClick={onClickHandler}
                        width= '28px'
                        height = '28px'
                    />
                </span>
    </div>
    <div className='row'>
        <div className='col-4 m-w-100' style={{position: 'relative', height: '100vh', padding: '20px 0px 0px 30px', justifyItems: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className="switch-container">
                    <div
                        className={`switch-option ${active === 'Amount' ? 'active' : ''}`}
                        onClick={() => {setAmountprice(false);toggleSwitch('Amount')}}
                    >
                        Amount
                    </div>
                    <div
                        className={`switch-option ${active === 'Points' ? 'active' : ''}`}
                        onClick={() => {setAmountprice(true);toggleSwitch('Points')}}
                    >
                        {brand?.subtype === 'cashback'?"Cashback":"Points"}
                    </div>
                    {brand?.subtype !== 'cashback'&&<div
                        className={`switch-option hide2 ${active === 'Deals' ? 'active' : ''}`}
                        onClick={() => {setAmountprice(false);toggleSwitch('Deals')}}
                    >
                        Deals
                    </div>}
                </div>

                {/* //mob */}
                <div className='w-100 hide2 justify-content-center'>
                    {active==='Deals'?<div>
                        <div className='m-m0 mt-50' style={{marginRight:'30px'}}>
                {/* <div className='d-flex space-between ' style={{alignItems: 'center', marginBottom: '16px'}}>
                    <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '11px', color: '#60606E' }} className='headAB  text-left d-flex justify-content-space-between'>
                        {deals && deals.length == 0 ? "No Deals Available" : "Available Deals"}
                    </div>
                    <button
                        type="button"
                        onClick={()=> setShow(!show)}
                        className="btn btn-primary ph-responsive"
                        style={{
                            backgroundColor: show&&'#E5E8F5',
                            color: show&&'#B2B7CA',
                            border: show&&'none',
                            height: '64px',
                            width: '200px'
                        }}
                    >
                        <b> {show?<SvgIcons.Hide/>:<SvgIcons.Show/>} {show?"Hide":"Show"}</b>
                    </button>
                </div> */}
                {dataloader ?
                    <div className='loader'>
                        <div className="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    : 
                <div style={{ 
                    display: 'grid', 
                    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', 
                    gap: '8px',
                    marginTop: '20px',
                    justifyContent: 'start' // Align items to the start of the grid line
                }}>
                    
                    <div className={'cp m-w-100'}  style={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 12.248px 20.997px 0px rgba(0, 0, 0, 0.05)',
                        padding: '12px',
                        border: selectedDeal === null && '1px solid #00F',
                        width: 'fit-content' // Set a fixed width for each grid item
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 20px 30px 0px rgba(0, 0, 0, 0.2)'}
                    onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}
                    onClick={() =>  {
                        // if (!deal.cwCode)  {
                        
                        // if(deal.valid === false) 
                        setSelectedDeal(null)
                        
                        }
                    }
                            // }
                            >
                        <img style={{
                            borderRadius: '14px',
                            objectFit: 'cover', // Ensures the image covers the area without distortion
                            width: '180px', // You can adjust this as needed
                            height: '128.606px' // You can adjust this as needed
                        }} className='m-w-100 mp0' src={PngIcons.NoDeal} width={'180px'} height={'128.606px'} alt={`Deal 00`} />
                        <div className='headAA' style={{ marginTop: '5px', marginBottom: '3px' }}>{'No Deal'}</div>
                    </div>
                {
                    deals && deals.length > 0 && deals.map((deal, idx) => (
                        
                        <div className={deal.valid === false ?'disabled-div m-w-100':'cp m-w-100'}  style={{
                            borderRadius: '10px',
                            background: '#FFF',
                            boxShadow: '0px 12.248px 20.997px 0px rgba(0, 0, 0, 0.05)',
                            padding: '12px',
                            border: deal.valid === true && selectedDeal && selectedDeal?.id == deal?.id && '1px solid #00F',
                            width: 'fit-content' // Set a fixed width for each grid item
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 20px 30px 0px rgba(0, 0, 0, 0.2)'}
                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}
                        onClick={() =>  {
                            // if (!deal.cwCode)  {
                            if(deal.valid === true) handleDealSelect(deal) 
                            // if(deal.valid === false) setSelectedDeal(null)
                            
                            }
                        }
                                // }
                                >
                            {console.log('selectedDeal && selectedDeal?.id == deal?.id',selectedDeal && selectedDeal?.id == deal?.id)}
                            <img style={{
                                borderRadius: '14px',
                                objectFit: 'cover', // Ensures the image covers the area without distortion
                                width: '180px', // You can adjust this as needed
                                height: '128.606px' // You can adjust this as needed
                            }} className='m-w-100 mp0' src={deal.image} width={'180px'} height={'128.606px'} alt={`Deal ${idx}`} />
                            <div className='headAA ' style={{ marginTop: '5px', marginBottom: '3px' }}>{deal.deal}</div>
                            <div className='conditionAA'>
                                {console.log('1st log', deal)}
                                { typeof deal.condition === 'string' ? deal.condition + ' ' + deal.conditionValue :
                                    deal.condition[0].condition === 'event' ? deal.condition[0].conditionValue :
                                    (deal.condition[0].conditionValue?.length > 0 && deal.condition[0].conditionValue[1]) ? deal.condition[0].conditionValue[1] + ' ' + deal.condition[0].condition : deal.condition[0].condition}
                            </div>
                        </div>
                    ))
                }
                </div>}
            </div>
                    </div>:<div className='w-100 m-justify-items-center'>
                    <div>
                    <input readOnly={openKeypad} value={!amountprice ? spending : redeeming} onChange={(e) => { !amountprice ? settingTheSpending(e) : setRedeeming(e.target.value) }} className='fs-88Imp ccSace res-spend first-blackLetter width-available' style={{ background: 'transparent', color: '#484951', border: 'none', outline: 'none' }} />
                </div>
                <div className='available-points d-flex justify-content-center' style={{gap: '8px'}}>
                    Available Points: <div>{passz && passz[passz.passType].currentPoints}</div>
                </div>
                <div className='keypad w-20px ma mt-24'>{KeyBoardKeys.map((ele, idx) => {return <div className='key keym' onClick={() => setKey(ele.data)}>{ele.key}</div>})}</div>
                
                    </div>}
                
                </div>
                <div className='width-available hide2' style={{
                        // position: 'absolute',
                        // bottom: '9px',
                        marginTop: '20px',
                        backgroundColor: '#DDD',
                        borderRadius: '8px'
                    }}>
                        <div className=' width-available'
                        >
                            <div className={
                                'width-available res-continue'
                            } onClick={() => CheckOutMain()} style={{
                                background: "#1059FA",
                                height: "72px", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', borderRadius: '8px'
                            }}>
                                <h2 className='fs-16' 
                                style={{ color: " #FFF" }}>{checkoutLoader ? <CircularProgress size={30} color='inherit' /> : 'Checkout'}</h2>
                            </div>
                        </div>

                    
                    </div>
                <div className='hide1'>
                    <input readOnly={openKeypad} value={!amountprice ? spending : redeeming} onChange={(e) => { !amountprice ? settingTheSpending(e) : setRedeeming(e.target.value) }} className='fs-88Imp ccSace res-spend first-blackLetter width-available' style={{ background: 'transparent', color: '#484951', border: 'none', outline: 'none' }} />
                </div>
                <div className='hide1 available-points d-flex justify-content-center' style={{gap: '8px'}}>
                    {brand?.subtype === 'cashback'?'Available Cashback:':'Available Points:'} <div>{passz && passz[passz.passType].currentPoints}</div>
                </div>
                <div className='keypad hide1 mt-24'>{KeyBoardKeys.map((ele, idx) => {return <div className='key' onClick={() => setKey(ele.data)}>{ele.key}</div>})}</div>
                <div className='width-available hide1' style={{
                        // position: 'absolute',
                        // bottom: '9px',
                        marginTop: '20px',
                        backgroundColor: '#DDD',
                        borderRadius: '8px'
                    }}>
                        <div className=' width-available'
                        >
                            <div className={
                                'width-available res-continue'
                            } onClick={() => CheckOutMain()} style={{
                                background: "#1059FA",
                                height: "72px", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', borderRadius: '8px'
                            }}>
                                <h2 className='fs-16' 
                                style={{ color: " #FFF" }}>{checkoutLoader ? <CircularProgress size={30} color='inherit' /> : 'Checkout'}</h2>
                            </div>
                        </div>

                    
                    </div>
        </div>
        <div className='col-8'>
            <div className=' hide1 pt_34' style={{ marginRight:'30px', display: 'flex', gap: '70px', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
  <path d="M20 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20C36.6666 10.7953 29.2047 3.33337 20 3.33337C10.7952 3.33337 3.33331 10.7953 3.33331 20C3.33331 29.2048 10.7952 36.6667 20 36.6667Z" stroke="#787878" stroke-width="1.5"/>
  <path d="M12.5 28.3333C16.3862 24.263 23.572 24.0713 27.5 28.3333M24.1585 15.8333C24.1585 18.1345 22.2903 20 19.9858 20C17.6815 20 15.8133 18.1345 15.8133 15.8333C15.8133 13.5321 17.6815 11.6666 19.9858 11.6666C22.2903 11.6666 24.1585 13.5321 24.1585 15.8333Z" stroke="#787878" stroke-width="1.5" stroke-linecap="round"/>
</svg>
                        <div className='customer-info'>
                            
                            <div className='customer-info-name'>{passz && passz.userData.firstName} {passz && passz.userData.lastName} </div>
                            <div className='customer-info-email'>{passz && passz.userData.email}</div>
                        </div>
                    </div>

                </div>
                <span className='home_back' onClick={() => history('/scanner')}>
                    
                    <img
                        className='login-black'
                        src={PngIcons.Bell}
                        alt=''
                        style={{cursor : 'pointer'}}
                        // onClick={onClickHandler}
                        width= '28px'
                        height = '28px'
                    />
                </span>
            </div>


            {brand?.subtype !== 'cashback'&&<div className='hide1 mt-50' style={{marginRight:'30px'}}>
                <div className='d-flex space-between ' style={{alignItems: 'center', marginBottom: '16px'}}>
                    <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '11px', color: '#60606E' }} className='headAB  text-left d-flex justify-content-space-between'>
                        {deals && deals.length == 0 ? "No Deals Available" : "Available Deals"}
                    </div>
                    <button
                        type="button"
                        onClick={()=> setShow(!show)}
                        className="btn btn-primary ph-responsive"
                        style={{
                            backgroundColor: show&&'#E5E8F5',
                            color: show&&'#B2B7CA',
                            border: show&&'none',
                            height: '64px',
                            width: '200px'
                        }}
                    >
                        <b> {show?<SvgIcons.Hide/>:<SvgIcons.Show/>} {show?"Hide":"Show"}</b>
                    </button>
                </div>
                {show ? (dataloader ?
                    <div className='loader'>
                        <div className="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                    : 
                    <div style={{ 
                        display: 'grid', 
                        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', 
                        gap: '8px',
                        justifyContent: 'start' // Align items to the start of the grid line
                    }}>
                        
                        <div className={'cp'}  style={{
                            borderRadius: '10px',
                            background: '#FFF',
                            boxShadow: '0px 12.248px 20.997px 0px rgba(0, 0, 0, 0.05)',
                            padding: '12px',
                            border: selectedDeal === null && '1px solid #00F',
                            width: 'fit-content' // Set a fixed width for each grid item
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 20px 30px 0px rgba(0, 0, 0, 0.2)'}
                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}
                        onClick={() =>  {
                            // if (!deal.cwCode)  {
                            
                            // if(deal.valid === false) 
                            setSelectedDeal(null)
                            
                            }
                        }
                                // }
                                >
                            <img style={{
                                borderRadius: '14px',
                                objectFit: 'cover', // Ensures the image covers the area without distortion
                                width: '180px', // You can adjust this as needed
                                height: '128.606px' // You can adjust this as needed
                            }} src={PngIcons.NoDeal} width={'180px'} height={'128.606px'} alt={`Deal 00`} />
                            <div className='headAA' style={{ marginTop: '5px', marginBottom: '3px' }}>{'No Deal'}</div>
                        </div>
                    {
                        
                        deals && deals.length > 0 && deals.map((deal, idx) => ((!deal.deal.includes('stamp'))&&
                            
                            <div className={deal.valid === false ?'disabled-div':'cp'}  style={{
                                borderRadius: '10px',
                                background: '#FFF',
                                boxShadow: '0px 12.248px 20.997px 0px rgba(0, 0, 0, 0.05)',
                                padding: '12px',
                                border: deal.valid === true && selectedDeal && selectedDeal?.id == deal?.id && '1px solid #00F',
                                width: 'fit-content' // Set a fixed width for each grid item
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 20px 30px 0px rgba(0, 0, 0, 0.2)'}
                            onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}
                            onClick={() =>  {
                                // if (!deal.cwCode)  {
                                if(deal.valid === true) handleDealSelect(deal) 
                                // if(deal.valid === false) setSelectedDeal(null)
                                
                                }
                            }
                                    // }
                                    >
                                {console.log('selectedDeal && selectedDeal?.id == deal?.id',selectedDeal && selectedDeal?.id == deal?.id)}
                                <img style={{
                                    borderRadius: '14px',
                                    objectFit: 'cover', // Ensures the image covers the area without distortion
                                    width: '180px', // You can adjust this as needed
                                    height: '128.606px' // You can adjust this as needed
                                }} src={deal.image} width={'180px'} height={'128.606px'} alt={`Deal ${idx}`} />
                                <div className='headAA' style={{ marginTop: '5px', marginBottom: '3px' }}>{deal.deal}</div>
                                <div className='conditionAA'>
                                    {console.log('1st log', deal)}
                                    { typeof deal.condition === 'string' ? deal.condition + ' ' + deal.conditionValue :
                                        deal.condition[0].condition === 'event' ? deal.condition[0].conditionValue :
                                        (deal.condition[0].conditionValue?.length > 0 && deal.condition[0].conditionValue[1]) ? deal.condition[0].conditionValue[1] + ' ' + deal.condition[0].condition : deal.condition[0].condition}
                                </div>
                            </div>
                        ))
                    }
                    </div>
                )
                :
                <div className='middle' style={{height: '50vh'}}>
                    <SvgIcons.Empty />
                    <div style={{marginTop: '34px', color: '#60606E',fontFamily: 'Inter',fontSize: '18px',fontStyle: 'normal',fontWeight: '400',lineHeight: '24px',letterSpacing: '0.2px'}}>Click here to view all deals</div>
                </div>
            }
            </div>}
            
        </div>
    </div>
</div>

            {/* <div id='spend' className='w-100' style={{ height: '100vh', }}>
                <div className='pb_15' style={{ width: '100%', backgroundColor: '#FBFBFB' }}>



                  
                </div>

                {!openKeypad &&
                    <div>
                        {brand?.integrations?.cloudwaitress?.length>0?'':<div className='deals mt-50'>
                            <div style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '11px', color: '#60606E' }} className='headingdeals  text-left d-flex justify-content-space-between'>
                                {deals && deals.length == 0 ? "No Deals Available" : "Available Deals"}
                                </div>
                            {dataloader ?
                                <div className='loader'>
                                    <div className="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>

                                : deals && deals.length > 0 && deals.map((deal, idx) => (
                                    <div className={!deal.valid ? 'deal invalid' : (selectedDeal && selectedDeal?.id == deal?.id) ? 'selected-deal deal' : 'deal'} 
                                    onClick={() =>  {
                                        if (!deal.cwCode)  {
                                            handleDealSelect(deal) }}
                                        }
                                        >
                                        <div className='colm1'>
                                            <div className='HeadlineH20M'>
                                                {deal.deal}
                                            </div>
                                            <div className='caption'>
                                                {deal.description}
                                            </div>
                                            <div className='condition'>
                                                {console.log('1stlog',deal)}
                                                { typeof deal.condition == 'string' ? deal.condition + ' ' + deal.conditionValue :
                                                 deal.condition[0].condition === 'event' ? deal.condition[0].conditionValue :
                                                (deal.condition[0].conditionValue?.length>0&&deal.condition[0].conditionValue[1]) ? deal.condition[0].conditionValue[1] + ' ' + deal.condition[0].condition : deal.condition[0].condition}
                                                
                                            </div>
                                        </div>
                                        <div className='colm2'>
                                            <img className='dealimage' src={deal.image} width={'100px'} height={'100px'} />
                                        </div>
                                    </div>
                                ))
                            }

                        </div>}
                    </div>}






                




            </div> */}
{console.log('selectedDeal',selectedDeal)}
            {

                success && <CustomsModal
                    open={true}
                    component={<SuccessModal
                        success={success}
                        setSuccess={setSuccess}
                        history={history}
                    />}
                    onClose={() => { setSuccess(!success) }}
                    minWidth={'498px'}
                />

            }
        </>

    )
}
function SuccessModal({ success, setSuccess, history }) {

    return (
        <div className='spendingModal'>
            <div className='right'>
                <svg width="78" height="78" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M44 0C19.8 0 0 19.8 0 44C0 68.2 19.8 88 44 88C68.2 88 88 68.2 88 44C88 19.8 68.2 0 44 0ZM64.9 35.5667L41.0667 57.5667C39.2333 59.0333 36.6667 59.0333 35.2 57.5667L23.4667 46.9333C22 45.4667 22 43.2667 23.1 41.8C24.2 40.3333 26.7667 40.3333 28.2333 41.4333L38.1333 50.2333L60.1333 30.0667C61.6 28.6 63.8 28.9667 65.2667 30.4333C66.7333 31.9 66.3667 34.4667 64.9 35.5667Z" fill="#1059FA" />
                </svg>
            </div>

            <div className='headsuccess'>Checkout
                Successfully Completed!</div>


            <button className='successBtn' onClick={() => { setSuccess(!success); history('/scanner') }}>
                Done
            </button>
        </div>
    )
}
