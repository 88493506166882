import './customer.scss'
import QrComponent from '../../components/QrComponent'
import CustomerComponent from '../../components/CustomerComponent'
import { useState, useEffect, useContext } from 'react'
import PngIcons from '../../assets/images/pngIcons'
import PhoneComponent from '../../components/PhoneComponent'
import EmailComponent from '../../components/EmailComponent'
import localforage from 'localforage'
import { useLocation, useNavigate } from 'react-router-dom'
import { utils } from '../../utils'
import { UserContext } from '../../context/User.context'
import SvgIcons from '../../assets/images/svgIcons'
import axios from 'axios'
import EmailComponent2 from '../../components/EmailComponent2'


export default function CustomerPage() {

  const user = useContext(UserContext)
  const location = useLocation();
  


  // const selectionType = [
  //   {
  //     title: 'Phone Number',
  //     description: 'Enter customer phone number to take action.',
  //     image: SvgIcons.One,
  //     component: <PhoneComponent />
  //   },
  //   {
  //     title: 'Customer Email',
  //     description: 'Enter customer email to take action.',
  //     image: SvgIcons.Three,
  //     component: <EmailComponent />
  //   },
  //   {
  //     title: 'Scanner',
  //     description: 'Scan customer qr code to take action.',
  //     component: <QrComponent />,
  //     image: SvgIcons.Two
  //   },
  // ]

  let [selectionType, setselectionType] = useState([
    {
      title: 'Customer Email',
      description: 'Enter customer email to take action.',
      image: SvgIcons.Three,
      component: <EmailComponent />
    },
    {
      title: 'Phone Number',
      description: 'Enter customer phone number to take action.',
      image: SvgIcons.One,
      component: <PhoneComponent />
    },
    {
      title: 'Customer Check-in',
      description: 'Enter email for self check-in.',
      image: 'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/e255e797-9278-4292-6ed9-5b5c04882b00/Businessconnector',
      component: <EmailComponent2 />
    },
  ])


const [media, setMedia] = useState(false);
const [reward, setReward] = useState(false);
const [brand, setBrand] = useState();
const [loader, setLoader] = useState(true);




  const [selectedComponent, setSelectedComponent] = useState(location?.state === 'Customer Check-in'? selectionType[2]: selectionType[0])

  const handleSelectFuc = selection => {
    setSelectedComponent(selection)
  }

  function onClickHandler() {
    utils.Logout();
  }
  const history = useNavigate();

//   const x = window.matchMedia("(max-width: 700px)")

// if(x.matches){
//   setMedia(true);
// }

// useEffect(() => {
  
// }, [x,media])

const [ch, setCh] = useState();

useEffect(() => {
  if(brand){
    setLoader(true)
    if (brand?.integrations?.cloudwaitress?.length>0) {
      let temp = []
      for (const element of selectionType) {
        if(element.title !== "Scanner" && element.title !== "Phone Number") temp.push(element)
      }
      setselectionType([...temp])
      // If the title "Scanner" exists, remove it
      // selectionType = selectionType.filter(item => item.title !== 'Scanner');
    } else {
      // if(selectionType.some(item => item.title === 'Scanner')){}
      // else{
      //   console.log('nomi');
        
        // If the title "Scanner" does not exist, add it
        if(brand?.subtype === 'cashback'){
          setselectionType([
            {
              title: 'Phone Number',
              description: 'Enter customer phone number to take action.',
              image: SvgIcons.One,
              component: <PhoneComponent />
            },
            {
              title: 'Customer Email',
              description: 'Enter customer email to take action.',
              image: SvgIcons.Three,
              component: <EmailComponent />
            },
            {
              title: 'Scanner',
              description: 'Scan customer QR code to take action.',
              component: <QrComponent />,
              image: SvgIcons.Two,
            }
          ])
        }
        else{
          setselectionType([
            {
              title: 'Phone Number',
              description: 'Enter customer phone number to take action.',
              image: SvgIcons.One,
              component: <PhoneComponent />
            },
            {
              title: 'Customer Email',
              description: 'Enter customer email to take action.',
              image: SvgIcons.Three,
              component: <EmailComponent />
            },
            {
              title: 'Customer Check-in',
              description: 'Enter email for self check-in.',
              image: 'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/e255e797-9278-4292-6ed9-5b5c04882b00/Businessconnector',
              component: <EmailComponent2 />
            },
            {
              title: 'Scanner',
              description: 'Scan customer QR code to take action.',
              component: <QrComponent />,
              image: SvgIcons.Two,
            }
          ])
        }
        setSelectedComponent({
          title: 'Phone Number',
          description: 'Enter customer phone number to take action.',
          image: SvgIcons.One,
          component: <PhoneComponent />
        })
        
      // }
    }
    console.log(selectionType);
    setLoader(false)
  }
}, [brand])

  useEffect(() => {

    checkFlows();

  }, [])
  

  const checkFlows = async ()=>{

    let channel = await localforage.getItem("channel");
    let brands = await localforage.getItem("brands");
    let selectedBrand = await localforage.getItem("selectedBrand");

    console.log('brands',brands);
    console.log('selectedBrand',selectedBrand);
    console.log(channel);
    
    if(!channel){
      console.log('1');
      
      try {
        const channel = await axios.get('https://api.walletly.ai/api/v4/growthtool?brandId='+(brands?.length===1?brands[0]._id:selectedBrand._id)+'&growthtoolType=landingPage');
        console.log('channel',channel);
        if(channel?.data?.data?.length>0) 
          setCh(channel.data.data[0])
      } catch (error) {
        console.log(error)
      }
    }
    
    
    if(brands.length===1) setBrand(brands[0])
    else setBrand(selectedBrand)

    let check = await localforage.getItem("flows");
    console.log("Check Flows",check);
    if(!check){
      // console.log("value",check)
      setReward(true);
    }
    else{
      setReward(false);
    }
    console.log("reward",reward);
  }

  return (
    <>
    {loader?<div className="Loader middle"><img height={40} width={40} src={PngIcons.Loader} alt="" /></div>:<div className='w-95 main-box-res' style={{ height: '100vh' , margin : '0 auto ' }} >
      {console.log('brand',brand)}
      
      <div style={{ height: '15%' , display : 'flex' , flexDirection : 'column',justifyContent : 'center'}} className='header-bg-res res-white res-padding'>
        {/* <div className='body-font res-white'>Logged in by:</div> */}
        <div style={{display : 'flex' , justifyContent : 'space-between'}}>
          <div className='d-flex align-items-center ' style={{gap: '18px'}}>
            <div><img className='borderRadius-50' src={brand?.brandLogo} alt="Brand Logo" width="42px" height="42px" /></div>
            {/* <div  style={{color: '#212529',fontFamily: 'Inter',fontSize: '14px',fontStyle: 'normal',fontWeight: '600',lineHeight: '16.8px',textTransform: 'capitalize'}}>{user && user.firstName + ' ' + user.lastName}</div> */}
            <div  style={{color: '#212529',fontFamily: 'Inter',fontSize: '14px',fontStyle: 'normal',fontWeight: '600',lineHeight: '16.8px',textTransform: 'capitalize'}}>{brand?.brandName}</div>
          </div>
          <div style={{display : 'flex'}}>
          <div>
          <img
            className='login-black'
            src={PngIcons.Bell}
            alt=''
            style={{cursor : 'pointer'}}
            onClick={onClickHandler}
            width= '28px'
            height = '28px'
          />
          {/* <img
            className='login-white'
            src={PngIcons.PowerWhite}
            alt=''
            style={{cursor : 'pointer'}}
            onClick={onClickHandler}
            width= '28px'
            height = '28px'
          /> */}

        </div>
       
          </div>
        </div>
       
      </div>


   
      <div style={{ height: '85%' }}>
        <div className=' d-flex justify-content-center customer responsive-boxes'>
          {selectionType.map(selection => (
            <div
              onClick={() => handleSelectFuc(selection)}
              // className={`cardBox cp d-flex ${selectedComponent.title ===
              //   selection.title && 'selected p-auto'}`}
            >
              {/* <div className='mr-15 middle'> */}
                {/* <img
                  src={selection.image}
                  alt=''
                  height={20}
                  width={20}
                  className='icon'
                ></img> */}
                {selection.title==='Customer Check-in'?<img src={selectedComponent.title===selection.title?'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/e255e797-9278-4292-6ed9-5b5c04882b00/Businessconnector':'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/6b5f1c6b-0d3c-4e59-8956-ba3627cf8a00/Businessconnector'}/>:
                <selection.image selected={selectedComponent.title===selection.title?true:false}/>
                }
              {/* </div> */}
              {/* <div>
                <div className='scanner'>{selection.title}</div>
                <div className='sub-text'>{selection.description}</div>
              </div> */}
            </div>
          ))}
          {/* <div className='cp' onClick={()=>window.open(brand?.channelDomain ? ('https://') + brand?.channelDomain + '/page/'+ch._id : ('https://') + `${'catchly.io'}/page/${ch._id}`, "_blank")}><img src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/13b7971e-7696-4b12-0cfa-1bedb3c0cc00/walletly' /></div> */}
          <div className='cp' onClick={()=>window.open(brand?.channelDomain ? ('https://') + brand?.channelDomain + '/page/'+ch._id : ('https://') + `${'catchly.io'}/page/${ch._id}`, "_blank")}><img src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/e8425559-8419-448d-b9be-c908f8583300/Businessconnector' /></div>
        </div>

        <div>
          <h1>{selectedComponent.component}</h1>
        </div>
      </div>
    




    </div>}
    </>





  )
}
