import * as yup from 'yup';

const LoginVld = yup.object().shape({
    userPhoneNumber : yup.string().required('Invalid Phone Number')//.min(9)
})


export {
    LoginVld
}
