import { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import localforage from "localforage";
import { LoginVld } from "../validations/PhoneNumber";
import { BusinessService } from "../services";
import CustomModal from "./CustomModal";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import SvgIcons from "../assets/images/svgIcons";

export default function PhoneComponent() {
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);

  const [modal, setModal] = useState(false);

  const [users, setUsers] = useState();

  useEffect(() => {
    gettingData();
  }, []);

  async function gettingData() {
    let data = await localforage.getItem("user");
    setUser(data);

    console.log(data);
  }

  const history = useNavigate();






 


  

  const handleSubmitFunc = async (e) => {
  e.preventDefault();

    setLoad(true);
    const user = await localforage.getItem('user');
    const selectedBrand = await localforage.getItem('selectedBrand')
    const brands = await localforage.getItem('brands')
    console.log('selectedBrand',selectedBrand);
    console.log('brands',brands);
    console.log('formik.values.userPhoneNumber',formik.values.userPhoneNumber);
    const { error, response } = await BusinessService.Phone({ query: { phone: formik.values.userPhoneNumber, idKey: user.idKey, brandId: selectedBrand?._id || brands[0]._id } });
    // const { error, response } = await BusinessService.Phone({ query: { phone: formik.values.userPhoneNumber, idKey: user.idKey, brandId: selectedBrand[0]._id } });

  
    // if (response && response.success) {
    //   setUsers(response.data);
    //   setLoad(false);
    //   setModal(true);
    // } else {
    //   console.log('error', error)
    // }
    console.log('User brands:', user.brands);
    console.log('Number of user brands:', user.brands ? user.brands.length : 'N/A');

    if (response && response.success) {
      let payload = {
        brandId: response.data[0].pass.brandId,
        passId: response.data[0].pass._id,
        growthToolId:response.data[0].growthToolId 
      }
      console.log('hamzaresponse',response);
      setUsers(response.data);
      setLoad(true);

   console.log('hamusers',user);
   console.log('hamusers',user.brands.length);
   console.log('newresponse',response);
      // if (user.brands && user.brands.length == 1) {
      if (response?.data?.length == 1) {
        console.log('payload',payload);
        const { response, error } = await BusinessService.GetQueriedGrowthtool({ query: { _id: payload.growthToolId } })
        if (response && response.success) payload.flowIds = response.data.flowId
    
        if (payload) {
          const { response, error } = await BusinessService.GetPublicPass({ query: { _id: payload.passId, delete: false } });

          console.log('newresomse',response);
          if (response) {
            history('/spend', { state: { pass: response.data[0], payload: payload } })
          }
    
        }
       
      } else {
        setModal(true);
      }
    } else {
      const selectedBrand = await localforage.getItem('selectedBrand')
      const brands = await localforage.getItem('brands')
      // console.log({ phone: formik.values.userPhoneNumber[0] === '0'?formik.values.userPhoneNumber.replace(/^0/, ''):formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand[0]._id })
      // console.log({ phone: formik.values.userPhoneNumber[0] === '0'?formik.values.userPhoneNumber.replace(/^0/, ''):formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand._id })
      const { error, response } = await BusinessService.Phone({ query: { phone: formik.values.userPhoneNumber[0] === '0'?formik.values.userPhoneNumber.replace(/^0/, ''):formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand?._id || brands[0]?._id } });
      // const { error, response } = await BusinessService.Phone({ query: { phone: formik.values.userPhoneNumber[0] === '0'?formik.values.userPhoneNumber.replace(/^0/, ''):formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand[0]._id } });
      if (response && response.success){
        let payload = {
          brandId: response.data[0].pass.brandId,
          passId: response.data[0].pass._id,
          growthToolId:response.data[0].growthToolId 
        }
        console.log('hamzaresponse',response);
        setUsers(response.data);
        setLoad(true);
  
     console.log('hamusers',user.brands.length);
     console.log('newresponse',response);
        if (response?.data?.length == 1) {
        // if (user.brands && user.brands.length == 1) {
          console.log('payload',payload);
          const { response, error } = await BusinessService.GetQueriedGrowthtool({ query: { _id: payload.growthToolId } })
          if (response && response.success) payload.flowIds = response.data.flowId
      
          if (payload) {
            const { response, error } = await BusinessService.GetPublicPass({ query: { _id: payload.passId, delete: false } });
  
            console.log('newresomse',response);
            if (response) {
              history('/spend', { state: { pass: response.data[0], payload: payload } })
            }
      
          }
         
        } else {
          setModal(true);
        }
      }
      else{
        // console.log('error', error);
        const selectedBrand = await localforage.getItem('selectedBrand')
        const brands = await localforage.getItem('brands')
        // console.log({ phone: formik.values.userPhoneNumber[0] === '0'?formik.values.userPhoneNumber.replace(/^0/, ''):formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand[0]._id })
        // console.log({ phone: formik.values.userPhoneNumber[0] === '0'?formik.values.userPhoneNumber.replace(/^0/, ''):formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand._id })
        const { error, response } = await BusinessService.Phone({ query: { phone: formik.values.userPhoneNumber[0] !== '0'?'0'+formik.values.userPhoneNumber:formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand?._id || brands[0]?._id } });
        // const { error, response } = await BusinessService.Phone({ query: { phone: formik.values.userPhoneNumber[0] === '0'?formik.values.userPhoneNumber.replace(/^0/, ''):formik.values.userPhoneNumber , idKey: user.idKey, brandId: selectedBrand[0]._id } });
        if (response && response.success){
          let payload = {
            brandId: response.data[0].pass.brandId,
            passId: response.data[0].pass._id,
            growthToolId:response.data[0].growthToolId 
          }
          console.log('hamzaresponse',response);
          setUsers(response.data);
          setLoad(true);
    
      console.log('hamusers',user.brands.length);
      console.log('newresponse',response);
          if (response?.data?.length == 1) {
          // if (user.brands && user.brands.length == 1) {
            console.log('payload',payload);
            const { response, error } = await BusinessService.GetQueriedGrowthtool({ query: { _id: payload.growthToolId } })
            if (response && response.success) payload.flowIds = response.data.flowId
        
            if (payload) {
              const { response, error } = await BusinessService.GetPublicPass({ query: { _id: payload.passId, delete: false } });
    
              console.log('newresomse',response);
              if (response) {
                history('/spend', { state: { pass: response.data[0], payload: payload } })
              }
        
            }
          
          } else {
            setModal(true);
          }
        }
        else{
          console.log('error', error);
        }
        console.log('error', error);
        // console.log('error', error);
      }
      console.log('error', error);
    }
    
    setLoad(false);
  };

  const initialValues = {
    userPhoneNumber: "",
  };



  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: LoginVld,
    isInitialValid: false,
  });
  const handlKeyDown = (e) => {
    if(e.code === 'Enter'){ handleSubmitFunc(e)}
  }
  //console.log(formik.values);

  return (
    // "customer" this class is removed from label for dev purposes
    <div className="label mt-70 plr-res">
      {/* <p className="user-id">
        Phone Number
      </p> */}
      <div style={{padding:'15px 24px 15px 24px', display:'flex', borderRadius: '8px',border: '1px solid #F2F2F2',background: '#FFF',boxShadow: '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}}>
        <div className="hide"><SvgIcons.Search /></div>
        <input
          name="userPhoneNumber"
          className="custom-input"
          type="number"
          placeholder="Enter customer's phone number"
          onChange={formik.handleChange}
          value={formik.values.userPhoneNumber}
          onKeyDown={(e) => handlKeyDown(e)}
          style={{
            all: 'unset',
            width: '100%', // Optionally specify the width if needed
            padding: '10px', // Optional padding for usability
          }}
        />
        <button
          type="button"
          onClick={handleSubmitFunc}
          className="btn btn-primary w-15 ph-responsive"
          disabled={!formik.isValid}
          style={{
            marginTop: '-10px',
            marginBottom: '-10px',
            marginRight: '-20px',
          }}
        >
          <b>{load ?
            <CircularProgress size={18} color={"inherit"} />
            : "Submit"}</b>
        </button>


      </div>
      <div className="d-flex btn-sheild">

        {
          modal && <CustomModal
            open={modal}
            component={<SelectCustomerModal
              users={users}
              setModal={setModal}
              modal={modal}
            />}
            onClose={() => { setModal(!modal) }}
            minWidth={'598px'}
          />
        }
      </div>

      <span className="error">
        {formik.errors.userPhoneNumber
          ? formik.errors.userPhoneNumber
          : error
            ? ""
            : ""}
      </span>
{console.log(formik.values)}
    </div>
  );
}



function SelectCustomerModal({ users, setModal, modal }) {

  const [load, setLoad] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null)
  const history = useNavigate();

  const handleSelect = async (element) => {
    setSelectedUser(element);
    setLoad(true)
    let payload = {
      brandId: element.pass.brandId,
      passId: element.pass._id,
    }

    const { response, error } = await BusinessService.GetQueriedGrowthtool({ query: { _id: element.pass.growthToolId } })
    if (response && response.success) payload.flowIds = response.data.flowId

    if (payload) {
      const { response, error } = await BusinessService.GetPublicPass({ query: { _id: element.pass._id, delete: false } });
      if (response) {
        history('/spend', { state: { pass: response.data[0], payload: payload } })
      }

    }
    setLoad(false)

  }


  return (

    

    <div className='customermodal'>
      <div className='navbar HeadingH24R'>
        <div className='cp' onClick={() => { setModal(!modal) }}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5002 0.516594C21.346 0.362068 21.1629 0.239471 20.9612 0.155823C20.7596 0.0721743 20.5435 0.0291174 20.3252 0.0291174C20.1069 0.0291174 19.8908 0.0721743 19.6891 0.155823C19.4875 0.239471 19.3044 0.362068 19.1502 0.516594L11.0002 8.64993L2.8502 0.499927C2.69602 0.345401 2.51287 0.222805 2.31125 0.139156C2.10963 0.0555081 1.89348 0.0124512 1.6752 0.0124512C1.45691 0.0124512 1.24077 0.0555081 1.03914 0.139156C0.837518 0.222805 0.654374 0.345401 0.500195 0.499927C-0.149805 1.14993 -0.149805 2.19993 0.500195 2.84993L8.6502 10.9999L0.500195 19.1499C-0.149805 19.7999 -0.149805 20.8499 0.500195 21.4999C1.1502 22.1499 2.2002 22.1499 2.8502 21.4999L11.0002 13.3499L19.1502 21.4999C19.8002 22.1499 20.8502 22.1499 21.5002 21.4999C22.1502 20.8499 22.1502 19.7999 21.5002 19.1499L13.3502 10.9999L21.5002 2.84993C22.1335 2.21659 22.1335 1.14993 21.5002 0.516594V0.516594Z" fill="black" />
          </svg>
        </div>


        <div className='text-center'>
          Customer Details
        </div>

      </div>

      <div className="subtext">
        It seems like there are multiple customers having same phone number, please select one:
      </div>
      
{/*         
      {
        load ? <div style={{ width: '100%', height: '200px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={18} color={"inherit"} />
        </div> :
          users && users.length > 0 && users.map((element, idx) => (
            <div className="user" onClick={() => { handleSelect(element) }}>
              <div className="s1">{element.user.firstName + ' ' + element.user.lastName}</div>
              <div className="s2 mt-8">{element.user.email}</div>
            </div>
          ))
      } */}



{users && users.length > 0 && users.map((element, idx) => (
        <div key={idx}>
          {load && selectedUser === element ? (
            <div style={{ width: '100%', height: '100px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={18} color={"inherit"} />
            </div>
          ) : (
            <div className="user" onClick={() => handleSelect(element)}>
              <div className="s1">{element.user.firstName + ' ' + element.user.lastName}</div>
              <div className="s2 mt-8">{element.user.email}</div>
            </div>
          )}
        </div>
      ))}
    </div>
   
     
  )
}
