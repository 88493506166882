import React, { useState } from 'react'
import PngIcons from '../assets/images/pngIcons'
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import CustomsModal from '../components/CustomModal';
import { BusinessService } from '../services';
import axios from 'axios';

function Checkin() {
    const history = useNavigate();
    const location = useLocation();
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [success, setSuccess] = useState(false);
    const CheckOutMain = async() => {
        setCheckoutLoader(true)
        let payload = {
            passId: location?.state?.pass?._id,
            brandId: location?.state?.pass?.brandId,
            location: {
                lat: location?.state?.brand?.location.lat,
                address: location?.state?.brand?.location.address,
                lng: location?.state?.brand?.location.lng
            },
            addPoints: '1',
            type : 'checkin points',
            platform : 'bc web customer'
        }

        setTimeout(() => {
            setCheckoutLoader(false)
            setSuccess(true);
            setTimeout(() => {
                setSuccess(!success); history('/scanner', { state: 'Customer Check-in' })
            }, 3000)
        }, 3000);
        const { response, error } = await BusinessService.BusinessCheckout({ payload });
        console.log('response',response);
            
    }
  return (
    <div>
        {console.log(location)}
        <div className='mt-30 mr-30' style={{ marginLeft:'20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div></div>
                <span className='home_back cp' style={{cursor : 'pointer'}} onClick={() => history('/scanner', { state: 'Customer Check-in' })}>
                    
                    <img
                        className='login-black'
                        src={PngIcons.Bell}
                        alt=''
                        style={{cursor : 'pointer'}}
                        // onClick={onClickHandler}
                        width= '28px'
                        height = '28px'
                    />
                </span>
    </div>
        <div style={{height: '90vh'}} className='middle'>
            <div className=''>
                <div className='ccmain mb-70 pl-15 pr-100'>Customer Check-in</div>
                <div className='d-flex gap-3 align-items-center mb-40 pl-50 pr-100'>
                    <div>
                        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 33.3184C26.2843 33.3184 33 26.6026 33 18.3184C33 10.0341 26.2843 3.31836 18 3.31836C9.71573 3.31836 3 10.0341 3 18.3184C3 26.6026 9.71573 33.3184 18 33.3184Z" stroke="#787878" stroke-width="2.06"/>
                        <path d="M11.25 25.8184C14.7476 22.1551 21.2148 21.9826 24.75 25.8184M21.7427 14.5684C21.7427 16.6394 20.0613 18.3184 17.9872 18.3184C15.9133 18.3184 14.232 16.6394 14.232 14.5684C14.232 12.4973 15.9133 10.8184 17.9872 10.8184C20.0613 10.8184 21.7427 12.4973 21.7427 14.5684Z" stroke="#787878" stroke-width="2.06" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <div className='cchead'>Full Name</div>
                        <div className='ccdesc'>{location?.state?.pass?.userData?.firstName+' '+location?.state?.pass?.userData?.lastName}</div>
                    </div>
                </div>
                <div className='d-flex gap-3 align-items-center mb-40 pl-50 pr-100'>
                    <div>
                    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.99982 9.03906L13.3693 14.9145C17.1922 17.0806 18.8074 17.0806 22.6303 14.9145L32.9998 9.03906" stroke="#787878" stroke-width="2.0625" stroke-linejoin="round"/>
                    <path d="M3.02347 20.2525C3.12153 24.8509 3.17056 27.1499 4.86726 28.8532C6.56394 30.5563 8.92531 30.6155 13.6481 30.7342C16.5588 30.8074 19.4409 30.8074 22.3516 30.7342C27.0744 30.6155 29.4357 30.5563 31.1325 28.8532C32.8291 27.1499 32.8782 24.8509 32.9761 20.2525C33.0078 18.7739 33.0078 17.3042 32.9761 15.8257C32.8782 11.2274 32.8291 8.9282 31.1325 7.22505C29.4357 5.52191 27.0744 5.46258 22.3516 5.34392C19.4409 5.27078 16.5588 5.27078 13.648 5.3439C8.92531 5.46255 6.56394 5.52188 4.86724 7.22504C3.17055 8.92818 3.12153 11.2273 3.02346 15.8257C2.99193 17.3042 2.99194 18.7739 3.02347 20.2525Z" stroke="#787878" stroke-width="2.0625" stroke-linejoin="round"/>
                    </svg>
                    </div>
                    <div>
                        <div className='cchead'>Email Address</div>
                        <div className='ccdesc'>{location?.state?.pass?.userData?.email}</div>
                    </div>
                </div>
                <div className='d-flex gap-3 align-items-center mb-60 pl-50 pr-100'>
                    <div>
                        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.7379 8.86815L13.1339 7.50918C12.739 6.62062 12.5416 6.17632 12.2463 5.83632C11.8762 5.41021 11.3938 5.09671 10.8541 4.93158C10.4234 4.7998 9.93724 4.7998 8.96486 4.7998C7.54241 4.7998 6.83119 4.7998 6.23414 5.07324C5.53085 5.39533 4.89571 6.09472 4.64264 6.8257C4.42781 7.44624 4.48936 8.08395 4.61242 9.35935C5.92241 22.9351 13.3653 30.378 26.9409 31.6879C28.2164 31.8111 28.8542 31.8726 29.4746 31.6578C30.2057 31.4047 30.905 30.7695 31.2272 30.0663C31.5005 29.4691 31.5005 28.758 31.5005 27.3355C31.5005 26.3631 31.5005 25.8769 31.3688 25.4463C31.2036 24.9066 30.8901 24.4242 30.464 24.0541C30.1241 23.7588 29.6798 23.5614 28.7912 23.1664L27.4322 22.5624C26.4699 22.1347 25.9887 21.921 25.4999 21.8745C25.0319 21.8299 24.5601 21.8956 24.1221 22.0662C23.6645 22.2444 23.2601 22.5814 22.451 23.2555C21.6458 23.9266 21.2432 24.2622 20.7512 24.4419C20.315 24.6012 19.7384 24.6603 19.2791 24.5925C18.7608 24.5161 18.3641 24.3042 17.5704 23.88C15.1014 22.5606 13.7398 21.199 12.4204 18.7299C11.9963 17.9364 11.7842 17.5395 11.7079 17.0214C11.6402 16.5619 11.6992 15.9853 11.8585 15.5493C12.0382 15.0572 12.3738 14.6546 13.0448 13.8493C13.719 13.0403 14.0561 12.6358 14.2343 12.1782C14.4048 11.7402 14.4705 11.2684 14.426 10.8005C14.3795 10.3116 14.1656 9.83044 13.7379 8.86815Z" stroke="#787878" stroke-width="2.0625" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div>
                        <div className='cchead'>Phone Number</div>
                        <div className='ccdesc'>{location?.state?.pass?.userData?.phone}</div>
                    </div>
                </div>
                <div className='width-available' style={{backgroundColor: '#DDD',borderRadius: '8px'}}><div className=' width-available'><div className={'width-available res-continue'} onClick={CheckOutMain} style={{background: "#1059FA",height: "72px", display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', borderRadius: '8px'}}> <h2 className='fs-16' style={{ color: " #FFF" }}>{checkoutLoader ? <CircularProgress size={30} color='inherit' /> : 'Check-in'}</h2></div></div></div>
            </div>
        </div>
        {
            success && <CustomsModal
                open={true}
                component={<SuccessModal
                    success={success}
                    setSuccess={setSuccess}
                    history={history}
                />}
                onClose={() => { setSuccess(!success) }}
                minWidth={'498px'}
            />

        }
    </div>
  )
}

export default Checkin


function SuccessModal({ success, setSuccess, history }) {

    return (
        <div className='spendingModal'>
            <div className='right'>
                <svg width="78" height="78" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M44 0C19.8 0 0 19.8 0 44C0 68.2 19.8 88 44 88C68.2 88 88 68.2 88 44C88 19.8 68.2 0 44 0ZM64.9 35.5667L41.0667 57.5667C39.2333 59.0333 36.6667 59.0333 35.2 57.5667L23.4667 46.9333C22 45.4667 22 43.2667 23.1 41.8C24.2 40.3333 26.7667 40.3333 28.2333 41.4333L38.1333 50.2333L60.1333 30.0667C61.6 28.6 63.8 28.9667 65.2667 30.4333C66.7333 31.9 66.3667 34.4667 64.9 35.5667Z" fill="#1059FA" />
                </svg>
            </div>

            <div className='headsuccess'>Checked-in
                Successfully Completed!</div>


            <button className='successBtn' onClick={() => { setSuccess(!success); history('/scanner', { state: 'Customer Check-in' }) }}>
                Done
            </button>
        </div>
    )
}
