import { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import localforage from "localforage";
import { LoginVld } from "../validations/Email";
import { BusinessService } from "../services";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import SvgIcons from "../assets/images/svgIcons";

export default function EmailComponent2() {
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);


  const history = useNavigate();

  useEffect(() => {
    gettingData();
  }, []);

  async function gettingData() {
    let data = await localforage.getItem("user");
    setUser(data);

    //console.log(data);
  }

  const handleSubmitFunc = async () => {

    setLoad(true);
    const user = await localforage.getItem('user');
    const selectedBrand1 = await localforage.getItem('brands')
    const selectedBrand2 = await localforage.getItem('selectedBrand')

  const { response, error } = await BusinessService.GetPublicPass({ query: { brandId: (user.brands && user.brands.length == 1)?selectedBrand1[0]._id:selectedBrand2._id, email: formik.values.Email  } });
  if(response){
    history('/checkin', { state: { pass: response.data[0], brand: (user.brands && user.brands.length == 1)?selectedBrand1[0]:selectedBrand2 } })
  }
    setLoad(false);
}


  const initialValues = {
    Email: "",
  };

  async function submitFunction(event) {
    event.preventDefault();
  }

  const formik = useFormik({
    initialValues: { ...initialValues },
    onSubmit: submitFunction,
    validationSchema: LoginVld,
    isInitialValid: false,
  });

  //console.log(formik.values);
  const handlKeyDown = (e) => {
    if(e.code === 'Enter'){ handleSubmitFunc()}
  }

  return (
    <div className="label mt-70 plr-res">
      {/* <div className="user-id">
        Customer Email
      </div> */}

      <div style={{padding:'15px 24px 15px 24px', display:'flex', borderRadius: '8px',border: '1px solid #F2F2F2',background: '#FFF',boxShadow: '0px 14px 24px 0px rgba(0, 0, 0, 0.05)'}}>

      <div className="hide"><SvgIcons.Search /></div>
      <input
        className="custom-input"
        type="email"
        name="Email"
        placeholder="Enter email for self check-in."
        onChange={formik.handleChange}
        value={formik.values.Email}
        onKeyDown={(e)=>handlKeyDown(e)}
        style={{
          all: 'unset',
          width: '100%', // Optionally specify the width if needed
          padding: '10px', // Optional padding for usability
        }}
      />
      <button
        type="button"
        onClick={handleSubmitFunc}
        className="btn btn-primary w-15 ph-responsive"
        disabled={!formik.isValid}
        style={{
          marginTop: '-10px',
          marginBottom: '-10px',
          marginRight: '-20px',
        }}
      >
        <b> {load ?
          <CircularProgress size={18} color={"inherit"} />
          : "Submit"}</b>
      </button>

      </div>
      {/* <div className="d-flex btn-sheild">
      </div> */}

      <span className="error">
        {formik.errors.Email
          ? formik.errors.Email
          : error
            ? ""
            : ""}
      </span>
    </div>
  );
}
